<template>
  <div>
    <div>
      <h4 class="dashboard__heading">Other Details</h4>
    </div>
    <b-card class="card-app-design assigned-staff-card">
      <h4>Student Registration Link</h4>
      <a :href="data.student_registration_link" target="_blank">
        <b-button
          size="sm"
          variant="outline-primary"
          class="btn-tour-skip mr-1"
        >
          <span class="mr-25 align-middle">Click Here</span>
          <!-- <feather-icon icon="XIcon" size="12" /> -->
        </b-button>
      </a>
    </b-card>
    <b-card class="card-app-design assigned-staff-card">
      <h4>Recruitment partner Brand Logo</h4>
      <!-- {{ FILESURL + data.agent_brand_info.brand_image }} -->
      <!-- <b-img
        :src="
          data.agent_brand_info && data.agent_brand_info.brand_image
            ? FILESURL + data.agent_brand_info.brand_image
            : ''
        "
      /> -->
      <b-img
        v-if="data.agent_brand_info"
        :src="FILESURL + data.agent_brand_info.brand_image"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BBadge,
  BCardTitle,
  BCardText,
  BAvatar,
  BButton,
  BImg
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { FILESURL } from "@/config";

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BAvatar,
    BCardTitle,
    BCardText,
    BImg
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      FILESURL,
    };
  },
};
</script>

<style>
.assigned-staff-card {
  font-weight: 600 !important;
}
.black-color {
  color: #000;
}
</style>
